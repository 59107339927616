<script setup lang="ts">
import { track } from '@vercel/analytics'

const config = useRuntimeConfig()
const toast = useToast()

const { t } = useLocale()
const { url } = useImageStorage()

const { auth } = useSupabaseClient()
const { naverLoginProcess } = useNaverLogin()

useHead({
  title: t('pageTitle.login'),
})

const getUrl = () => {
  let url = useRuntimeConfig().public.siteUrl ?? 'http://localhost:7000/'
  url = url.includes('http') ? url : `https://${url}`
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`
  return url.concat('confirm')
}

const userLogin = (provider: 'kakao' | 'naver' | 'apple' | 'google') => {
  track('Login Provider', { provider })
  switch (provider) {
    case 'apple' :
      loginOauthApple(provider)
      break
    case 'naver' :
      loginNaver()
      break
    default :
      loginOAuth(provider)
  }
}

const loginNaver = () => {
  const naverLogin = new window.naver.LoginWithNaverId({
    clientId: `${config.public.naverLoginClientId}`,
    callbackUrl: `${config.public.siteUrl ?? 'http://localhost:7000'}/login/naver/`,
    callbackHandle: true,
    isPopup: false,
  })

  naverLogin.getLoginStatus(async (status: string) => {
    if (status) {
      naverLoginProcess(naverLogin.user.email, naverLogin.user.id, naverLogin.user.nickname)
    }
    else {
      naverLogin.reprompt()
    }
  })
}

const loginOauthApple = async (provider: 'apple') => {
  await auth.signInWithOAuth({
    provider,
    options: {
      redirectTo: getUrl(),
    },
  })
}

const loginOAuth = async (provider: 'kakao' | 'apple' | 'google') => {
  const { error } = await auth.signInWithOAuth({
    provider,
    options: {
      redirectTo: getUrl(),
    },
  })

  if (error) {
    toast.add({ title: t('message.loginFailed.title'), description: t('message.loginFailed.description'), color: 'rose', timeout: 2000 })
    return
  }
}
</script>

<template>
  <div class="w-dvw h-[calc(100dvh-92px)] tablet:w-[500px] flex flex-col justify-center items-center px-8">
    <div class="flex flex-col justify-center items-center gap-8">
      <div
        class="flex flex-col items-center gap-y-5 mb-2 cursor-pointer"
        @click="navigateTo('/')"
      >
        <NuxtImg
          :src="url(true, '/assets/logo/wnm_logo.svg')"
          :width="76"
        />
        <p class="text-center text-4xl font-bold break-keep">
          {{ $t('login.title') }}
        </p>
      </div>
      <LoginGroup @click:login="(provider: 'kakao' | 'naver' | 'apple' | 'google') => userLogin(provider)" />
      <div class="flex items-center justify-center flex-wrap gap-x-1.5">
        <span>
          {{ $t('login.policy.first') }}
        </span>
        <WMLink
          to="/policy/terms"
          inactive-class="font-semibold text-gray05Light dark:text-gray05Dark"
        >
          {{ $t('login.policy.terms') }}
        </WMLink>
        <span>
          {{ $t('login.policy.second') }}
        </span>
        <WMLink
          to="/policy/privacy"
          inactive-class="font-semibold text-gray05Light dark:text-gray05Dark"
        >
          {{ $t('login.policy.privacy') }}
        </WMLink>
        <span>
          {{ $t('login.policy.third') }}
        </span>
      </div>
    </div>
  </div>
</template>
