<script setup lang="ts">
const colorMode = useColorMode()
const { url } = useImageStorage()

defineEmits([
  'click:login',
])
</script>

<template>
  <div class="w-full flex flex-col items-center gap-2.5">
    <AButton
      custom-class="w-full text-base ring-gray03Light dark:ring-gray03Light"
      button-block
      button-variant="outline"
      button-color="black"
      button-size="xl"
      use-leading
      :image-url="url(true, `/assets/login/${colorMode.value === 'light' ? 'kakao' : 'kakao_dark'}.svg`)"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.kakao')"
      @click="() => $emit('click:login', 'kakao')"
    />
    <AButton
      custom-class="w-full text-base ring-gray03Light dark:ring-gray03Light"
      button-block
      button-variant="outline"
      button-color="black"
      button-size="xl"
      use-leading
      :image-url="url(true, '/assets/login/naver.svg')"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.naver')"
      @click="() => $emit('click:login', 'naver')"
    />
    <AButton
      custom-class="w-full text-base ring-gray03Light dark:ring-gray03Light"
      button-block
      button-variant="outline"
      button-color="black"
      button-size="xl"
      use-leading
      :image-url="url(true, '/assets/login/google.svg')"
      :image-size="20"
      image-class="absolute left-5"
      :button-text="$t('button.google')"
      @click="() => $emit('click:login', 'google')"
    />
    <AButton
      custom-class="w-full text-base ring-gray03Light dark:ring-gray03Light"
      button-block
      button-variant="outline"
      button-color="black"
      button-size="xl"
      use-leading
      :image-url="url(true, `/assets/login/${colorMode.value === 'light' ? 'apple' : 'apple_dark'}.svg`)"
      image-class="absolute w-4 left-5"
      :button-text="$t('button.apple')"
      @click="() => $emit('click:login', 'apple')"
    />
  </div>
</template>
